import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'maxNumber',
	standalone: true,
})
export class MaxNumberPipe implements PipeTransform {
	transform(value: number, maxNumber: number = 99): string {
		return value > 0 ? (value > maxNumber ? `${maxNumber}+` : value.toString()) : '';
	}
}
